import React, { Fragment } from 'react';

import Layout from '../components/layout';
import PasswordForgetForm from '../components/PasswordForget';
import { AppWrapper } from '../components/styled';

const PasswordForgetPage = () => (
  <Fragment>
    <h1>PasswordForget</h1>
    <PasswordForgetForm />
  </Fragment>
);

export default () => (
  <Layout>
    <AppWrapper>
      <PasswordForgetPage />
    </AppWrapper>
  </Layout>
);
